'use client';
import { useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import {
  CollectionParams,
  createCollectionQuery,
} from 'models/Collection/CollectionQuery';
import { CollectionItem } from 'models/Collection/CollectionType';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Link from 'next/link';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import { audioDownloadPrefix } from 'utils/utils';
import Loading from 'components/Loading/Loading';
import OpenableList from 'components/OpenableList/OpenableList';
import { HomeProps } from '../Home';
import Image from 'next/image';

dayjs.extend(utc);
dayjs.extend(timezone);

const MnTodayPromo = ({ radar }: HomeProps) => {
  const [stories, setStories] = useState<CollectionItem[] | undefined>();
  const storiesRef = useRef(null);
  const subscribeLinks = [
    {
      Apple:
        'https://itunes.apple.com/us/podcast/mpr-news-update-mpr-news/id525807840?mt=2',
    },
    {
      Amazon:
        'https://music.amazon.com/podcasts/dc97b77d-0317-4cb3-8306-5df262abe4c2/minnesota-today',
    },
    {
      Spotify: 'https://open.spotify.com/show/3ovoBjaXEM7TUwiCFSCiRO',
    },
    {
      RSS: 'https://feeds.publicradio.org/public_feeds/mpr-news-update/rss/rss',
    },
  ];

  const showRadar = JSON.parse(radar.json).general.radar_on;
  // const showRadar = true;
  const startNumber = showRadar ? 6 : 7;
  let dataFetched = false;

  async function getData() {
    if (!dataFetched) {
      const bucket: CollectionItem[] = [];
      const collectionParams: CollectionParams = {
        slug: 'homepage',
        contentAreaSlug: process.env.CONTENT_AREA_SLUG || 'mprnews',
        pageSize: 15,
      };
      const data = await Promise.all([
        fetcher(COLLECTIONQUERY, {
          contentAreaSlug: process.env.CONTENT_AREA_SLUG,
          slug: 'podcasts/minnesota-today',
        }),
        fetcher(createCollectionQuery(collectionParams), {
          contentAreaSlug: process.env.CONTENT_AREA_SLUG,
          slug: 'homepage',
        }),
      ]);
      dataFetched = true;
      data.forEach((results, idx) => {
        if (idx == 0) {
          bucket.push(results?.collection.results.items[0]);
        } else {
          bucket.push(
            ...results?.collection.results.items.slice(startNumber, 13)
          );
        }
      });
      setStories(bucket);
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (storiesRef.current) observer.observe(storiesRef.current);
    return () => {
      if (storiesRef.current) observer.unobserve(storiesRef.current);
    };
  }, []);

  if (!stories) {
    return (
      <section ref={storiesRef}>
        <Loading />
      </section>
    );
  }

  return (
    <section ref={storiesRef} className="home-mn-today">
      <div className="home-mn-today-subscribe">
        <div className="home-mn-today-top">
          <Link
            href="/podcasts/minnesota-today"
            passHref
            className="home-mn-today-img"
          >
            <h2>
              <Image
                src="/img/Minnesota-Today.png"
                alt="Minnesota Today"
                width={93}
                height={93}
              />
            </h2>
          </Link>
          <div className="home-mn-today-player-controls">
            {stories && stories[0].primaryAudio?.encodings[0] && (
              <AudioPlayButton
                audioTitle={stories[0].title}
                label="Latest episode"
                audioSource={audioDownloadPrefix(
                  stories[0]?.primaryAudio?.encodings[0].playFilePath
                )}
                showDuration={`${
                  stories[0]?.primaryAudio?.encodings[0].durationHms || ''
                }`}
                iconWidth={'2.25rem'}
              />
            )}
          </div>
        </div>

        <div className="home-mn-today-bottom">
          <div className="type-sm">{stories && stories[0].title}</div>
          <div className="type-lg">
            <strong>Minnesota Today</strong>: the latest news in about five
            minutes, every weekday morning and evening.
          </div>
          <OpenableList links={subscribeLinks} />
        </div>
      </div>
    </section>
  );
};

export default MnTodayPromo;
